import Container from "react-bootstrap/Container";

function Home() {
  return (
    <Container>
      <h1>Tempting Headline</h1>
      <p>
        <a href="https://google.com/search?q=hot+tortoise">What is a Hot Tortoise</a>?
      </p>
    </Container>
  );
}

export default Home;
