import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import logo from './logo.svg';

export default function Layout() {
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="md" className="mb-3">
        <Container>
          <Navbar.Brand>
            <img src={logo} alt="Tortoise Logo" className="d-inline-block align-top me-2" height={24} width={24} />
            <span className="fs-4">Hot Tortoise</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer>
              <LinkContainer to="/thingy"><Nav.Link>Thingy</Nav.Link></LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>);
}
