import Container from "react-bootstrap/Container"

export default function Thingy() {
  return (
    <Container>
      <main>
        <h2>Is it a Thingy?</h2>

        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id nisl eget nisi lacinia posuere in sit amet lacus. Praesent augue nunc, porttitor sed dolor sit amet, gravida lacinia ante. Quisque feugiat, nibh ac scelerisque hendrerit, urna eros lobortis orci, et aliquam turpis leo eu metus. Morbi non condimentum magna. Curabitur hendrerit scelerisque augue eget congue. Quisque suscipit quam sed nisl posuere, quis volutpat nulla iaculis. Nulla posuere turpis mi, sed congue augue auctor in. Nulla facilisi. Maecenas ex ligula, ultrices et elit id, bibendum volutpat mauris. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris eget est ac nunc sollicitudin faucibus et nec justo. Pellentesque suscipit luctus risus ut tincidunt. Donec luctus justo sit amet quam dapibus posuere. Nulla imperdiet, velit sit amet semper tincidunt, nunc dolor suscipit risus, quis dictum nunc odio volutpat quam. Proin id feugiat justo.</p>

        <p>Ut pellentesque, dolor ut feugiat vehicula, tortor sem vestibulum elit, sed ornare risus libero vitae dolor. Quisque et odio ex. Fusce in tempus libero, a fermentum est. Ut volutpat, dolor sed volutpat tempor, metus sapien bibendum nulla, vitae fermentum libero leo id justo. Praesent rutrum, diam et aliquam faucibus, mauris tellus posuere diam, a tincidunt ex libero sed purus. Cras accumsan libero at orci consequat gravida sit amet vel ligula. Donec eros metus, laoreet ac dictum ut, semper quis lacus. Suspendisse commodo scelerisque felis, vestibulum faucibus nisl pulvinar quis. Curabitur eget molestie neque.</p>

        <p>Donec sodales quam eget eleifend aliquam. Aliquam rhoncus dui scelerisque ante maximus, vitae aliquam nunc aliquam. Maecenas sit amet eros tincidunt, maximus lacus sed, consequat dolor. Mauris ornare tortor in nisi feugiat, ac faucibus arcu iaculis. Fusce eget sagittis leo. Donec fermentum luctus tincidunt. Nulla elit velit, laoreet ac ultricies ac, efficitur bibendum diam. Suspendisse potenti. Etiam in mi purus. In in cursus leo, et tincidunt dolor. Aenean fringilla, tellus quis tincidunt lacinia, sem elit pharetra ipsum, eu viverra libero erat gravida diam. Donec ornare interdum arcu pellentesque accumsan. Praesent sagittis vulputate est nec placerat. Suspendisse odio nisl, condimentum sed malesuada sed, tincidunt eu libero. Aenean vitae mauris tempor, ultrices leo sit amet, ultricies sapien. Nulla laoreet neque non mauris condimentum, nec suscipit odio varius.</p>

        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper ipsum elementum eros lacinia convallis. Vestibulum cursus convallis purus, non convallis erat egestas et. Morbi bibendum bibendum purus. Nullam dictum dolor facilisis, aliquam quam vel, sagittis ipsum. Donec pellentesque convallis pellentesque. Aenean aliquam odio gravida elit mattis, eu gravida ex cursus. Sed lacinia enim ac metus rutrum, non viverra ipsum efficitur. Aenean nec mi viverra, iaculis ante ac, sodales lectus. Maecenas scelerisque lorem eget tellus cursus egestas ut ut justo.</p>

        <p>Etiam porta efficitur mi et viverra. Sed pulvinar enim in elit consequat dictum. In facilisis felis diam, at blandit massa facilisis quis. Fusce porttitor pretium leo suscipit sollicitudin. Sed at sapien quis lorem commodo mattis quis non massa. Vivamus in volutpat sapien. Morbi iaculis dapibus enim, ornare semper sapien accumsan eget. Morbi at sollicitudin sem. Vestibulum tristique lobortis tellus id pellentesque. Morbi mattis, massa in consequat efficitur, sem lacus euismod felis, euismod accumsan dolor ligula ut erat. Donec in facilisis neque, finibus commodo justo. Pellentesque convallis finibus turpis.</p>
      </main>
    </Container>
  );
}
